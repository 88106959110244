.business-card {
  width: 350px;
  height: 150px;
  position: absolute;
  background-color: white;
  color: black;
  border: 1px solid black;
  margin: 5px;
  padding: 5px 10px;
  filter: drop-shadow(5px 2px 2px #000000);
}

.business-card .logo {
  max-width: 100px;
}
.business-card .hero-image {
  max-width: 100px;
  position:absolute;
  right:5px;
}

.business-card .details {
  display: inline-block;
  padding: 5px;
}

.business-card .name {
  fontSize: 1.2em;
  font-weight: bold;
}

.business-card .job-title {
  font-size: 0.6em;
}

.business-card .company {
  font-weight: bold;
}

.business-card .location {
  font-size: 0.6em;
}

.business-card .email {}

.business-card .date {
  font-size: 0.6em;
  position: absolute;
  top:0;
  right:5px;
}

.business-card .links {
  padding: 5px 10px;
  font-size: 1.8em;
  position: absolute;
  bottom: 0;
  right: 0;
}

.business-card .links a:link,
.business-card .links a:hover,
.business-card .links a:active,
.business-card .links a:visited {
  color: #000;
  text-decoration: none;
  padding: 3px;
}
.business-card .links a.twitter:link,
.business-card .links a.twitter:hover,
.business-card .links a.twitter:active,
.business-card .links a.twitter:visited {
  color: #1DA1F2;
}
