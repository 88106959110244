.main-nav {
  background-color: rgb(173, 79, 24);
  color: rgb(255, 255, 255);
  padding: 1px;
}

.main-nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
.main-nav ul li {
  float: left;
}
.main-nav ul a:hover,
.main-nav ul a:active,
.main-nav ul a:link,
.main-nav ul a:visited {
    color: #fff;
    text-decoration: none;
    padding: 3px;
}
.main-nav ul a:hover {
  background-color: rgb(199, 138, 103);
}
