.edit-card .card-view {
  height: 200px;
  width: 350px;
  margin: 0 auto;
}
.edit-card .content {
  margin: 10px;
}
.edit-card .content input {
  border: none;
  border-bottom: 2px solid black;
  width: 300px;
}
.edit-card .content textarea {
  border: none;
  border-bottom: 2px solid black;
  width: 300px;
  height: 80px;
}
